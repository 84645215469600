
// customised for THe Noted Liar
// Oct 2023

// requires accompanying CSS file

const _ = require('lodash');
const Bliss = require('blissfuljs');
const Color = require('color');
import { Howl, Howler } from 'howler'
import { SinglePath, interpolate, lineToAngle, angleBetween } from './drawing.js';

const playerGrainDefault = 1000;
const defaults = {
    onplaychange : null,
    noInfo : false,
    noFlex: false,
    buttonRadius : .15,
    fontFamily : 'sans-serif',
    labelSize : .065,
    playerGrainMax : playerGrainDefault,
    playhead : 0,
    playheadColor : "black",
    playheadLabelAngle : 30,
    runnerRadius : .27,
    stateColor : null,
    src: null,
    trackRadius : .3,
    trackColor : "rgb(210,210,210)",
    trackLength : 30, // track length in seconds
    trackWidth : .0075,
    trackVariation : 3 // factor to bulge thickest part of track
};

const timeFormat = (seconds)=>`${_.padStart(_.floor(seconds/60),2,"0")}:${_.padStart(_.floor(seconds%60),2,"0")}`;

class RotoPlayer {

    constructor (element,options){
        if (!element) {
            throw 'RotoPlayer: cannot initialise without hosting element';
        }
        else {
            this.hostEl = element;
        }
        _.assign(this,defaults,options);
        return this;
    }

    initialise () {
        this.hostId = _.uniqueId("roto_player_");
        this.canvasId = _.uniqueId("roto_canvas_");
        this.hostEl._.set({
            id : this.hostId,
            className : this.hostEl.className+" rotary-player",
            style : {
                width : `${this.hostEl.clientWidth}px`,
                height : (this.noInfo || this.noFlex) ? `${this.hostEl.clientHeight}px` : `${this.hostEl.clientWidth*0.4}px`
            },
            contents : [{
                tag : "div",
                className : "player-surface",
                contents : [{
                    tag : "canvas",
                    id : "track_"+this.canvasId
                },{
                    tag : "canvas",
                    id : "runner_"+this.canvasId
                },{
                    tag : "canvas",
                    id : "playhead_"+this.canvasId
                }],
                events : {
                    click : (evt)=>{
                        this.handleClick(evt,this);
                    }
                }
            },(this.noInfo ? null : {
                tag : "div",
                className  : "player-info",
                style : {
                    font : `${this.hostEl.clientWidth/22}px / 1.2 ${this.fontFamily}`
                }
            })]
        });

        this.update();

        // set an interval to update once font found...
        let player = this;
        let fontCheck = setInterval(()=>{
            if (document.fonts.check(`1em ${player.fontFamily}`)) {
                clearInterval(fontCheck);
                player.update();
            }
        },250);

        window.addEventListener('resize',(evt)=>{
            player.update();
        });

        return this;
    }

    update () {
        this.playerWidth = $(".player-surface",this.hostEl).clientWidth;
        this.playerHeight = $(".player-surface",this.hostEl).clientHeight;
        $$(".player-surface canvas",this.hostEl).forEach(canv=>{
            canv._.set({
                height : this.playerHeight * devicePixelRatio,
                width: this.playerWidth * devicePixelRatio
            });
        });
        this.center = [this.playerWidth/2,this.playerHeight/2];
        this.drawTrack();
        return this;
    }

    drawPlayhead() {
        this.playheadAngle = 360 * (this.playhead / this.trackLength);
        this.playheadLabelAngle = this.playheadAngle > 180 ? _.clamp(this.playheadAngle,205,335) : _.clamp(this.playheadAngle,25,155); 
        // this.playheadLabelAngle = _.clamp(this.playheadAngle,20,340);
        this.playerCanv = this.playerCanv || $(`canvas#playhead_${this.canvasId}`);
        this.playerCtx = this.playerCtx || this.playerCanv.getContext('2d');
        this.playerPath = this.playerPath || new SinglePath();
        this.playerCtx.fillStyle = this.playheadColor;
        this.playerCtx.clearRect(0,0,this.playerWidth*devicePixelRatio,this.playerHeight*devicePixelRatio);
        this.playerPath.arc({center:this.center,radius:this.unit(this.trackRadius),startAng:270,endAng:270+360}).drawVariablePath(this.playerCtx,{
            thickest:this.unit(this.trackWidth)*(this.trackVariation+0.2 || 1),
            thinnest:this.unit(this.trackWidth),
            jitter:this.trackJitter || 0.01,
            extent:this.playhead / this.trackLength,
            pen:this.trackPen || "dot",
            grain:this.playerGrainMax,
            easing:this.trackEasing || undefined
        });
        this.playerCtx.font = `${this.unit(this.labelSize)*devicePixelRatio}px/1 ${this.fontFamily}`;
        let bulgeOut = _.clamp(this.playheadLabelAngle%180,60,120)-90;
        bulgeOut *= bulgeOut < 0 ? -0.01 : 0.01;
        let at = _.values(lineToAngle({
            x1 : this.center[0],
            y1 : this.center[1],
            angle : this.playheadLabelAngle+270,
            length : this.unit(this.trackRadius)+this.unit(this.trackRadius*0.235*(1.3 - bulgeOut))
        }));
        this.label({
            at,
            str : timeFormat(this.playhead),
            ctx : this.playerCtx
        });
    }

    drawTrack() {
        const canv = $(`canvas#track_${this.canvasId}`);
        let path = new SinglePath().arc({center:this.center,startAng:270,endAng:270+360,radius:this.unit(this.trackRadius)});
        let ctx = canv.getContext("2d");
        ctx.fillStyle = this.trackColor;
        path.drawVariablePath(ctx,{
            thickest:this.unit(this.trackWidth)*(this.trackVariation || 1),
            thinnest:this.unit(this.trackWidth),
            jitter:this.trackJitter || 0.01,
            pen:this.trackPen || "dot",
            easing:this.trackEasing || undefined
        });
        this[`draw${this.running ? "Pause" : "Play"}`].call(this,({ctx,center:this.center,radius:this.unit(this.buttonRadius)}));
        ctx.font = `${this.unit(this.labelSize)*devicePixelRatio}px/1 ${this.fontFamily}`;
        ctx.fillStyle = Color(this.trackColor).darken(0.28).string();
        this.label({
            str : timeFormat(this.trackLength),
            ctx,
            at: [this.center[0],this.center[1]-this.unit(this.trackRadius)-this.unit(this.trackRadius*0.14)]
        });
        this.trackCtx = ctx;
    }

    drawRunnerArc({grain=26}={}) {
        this.runnerCtx.clearRect(0,0,this.playerWidth*devicePixelRatio,this.playerHeight*devicePixelRatio);
        if (grain < 1) return;
        // start and end reversed because I'm a doofus
        this.runnerPath.arc({
            endAng:this.runnerAngle+30,
            startAng:this.runnerAngle-30,
            center:this.center,
            radius:this.unit(this.runnerRadius)
        }).drawVariablePath(this.runnerCtx,{
            thinnest : this.unit(this.trackWidth*0.3),
            thickest: this.unit(this.trackWidth),
            grain,
            jitter:0.01,
            pen:"dot"
        });
    }

    drawPause({center,radius,ctx}){
        // default pause mark (as for EWS - rewrite all this to CSS for showcasing?)
        // custom function can be added to the object
        if (_.isFunction(this.customPauseMark)) {
            return this.customPauseMark.call(this,...arguments);
        }
        let path = new SinglePath().arc({center,radius:radius*0.05,startAng:0,endAng:360});
        path.drawVariablePath(ctx,{thickest:radius*0.15,thinnest:radius*0.15,easing:"linear",jitter:0.5,pen:"dot"});
        path = new SinglePath().arc({center,radius:radius,startAng:170,endAng:370});
        path.drawVariablePath(ctx,{thickest:radius*0.15,thinnest:radius*0.05,jitter:0.1,pen:"dot"});
        return;
    }

    drawPlay({center,radius,ctx}) {
        // default play mark (as for EWS - rewrite all this to CSS for showcasing?)
        // custom function can be added to the object
        if (_.isFunction(this.customPlayMark)) {
            console.log("custom play mark");
            return this.customPlayMark.call(this,...arguments);
        }
        let path = new SinglePath().line({
            center:[center[0]+radius*0.1,center[1]+radius*0.38],
            angle : 330,
            length : radius * 1.5
        });
        path.drawVariablePath(ctx,{thinnest:radius*0.08,thickest:radius*0.04,jitter:0.1,pen:"dot"});
        path = new SinglePath().line({
            center:[center[0]+radius*0.1,center[1]-radius*0.38],
            angle : 30,
            length : radius * 1.5
        });
        path.drawVariablePath(ctx,{thinnest:radius*0.08,thickest:radius*0.04,jitter:0.1,pen:"dot"});

        return;
    }

    startRunning() {
        this.running = true;
        if (_.isFunction(this.onplaychange)) this.onplaychange.call(this,this);
        this.update();
        this.runnerAngle = 0;
        this.runnerCanv = this.runnerCanv || $(`canvas#runner_${this.canvasId}`);
        this.runnerCtx = this.runnerCtx || this.runnerCanv.getContext('2d');
        this.runnerPath = this.runnerPath || new SinglePath();
        this.runnerLoop = ()=>{
            this.runnerAngle = this.runnerAngle > 360 ? this.runnerAngle - 354 : this.runnerAngle + 6;
            this.drawRunnerArc();
            if (!!this.running) {
                let runnerLoop = this.runnerLoop;
                return setTimeout(function(){
                    window.requestAnimationFrame(runnerLoop);
                },2.77);
            }
            else { return; }
        };
        this.runnerLoop();
        return;
    }

    stopRunning() {
        this.running = false;
        if (_.isFunction(this.onplaychange)) this.onplaychange.call(this,this);
        this.update();
        let grain = 26;
        this.runnerLoop = ()=>{
            this.runnerAngle = this.runnerAngle > 360 ? this.runnerAngle - 354 : this.runnerAngle + 6;
            grain=grain-2;
            this.drawRunnerArc({grain});
            let runnerLoop = this.runnerLoop;
            return grain <= 0 ? false :  setTimeout(function(){
                window.requestAnimationFrame(runnerLoop);
            },2.77);
        }
        this.runnerLoop();
        return;
    }


    label({str,ctx,at}) {
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(str,...at.map(v=>v*devicePixelRatio));
        return this;
    }

    clipInfo(state){
        const {trackColor,playheadColor,stateColor} = this;
        if (!$(".player-info",this.hostEl)) return;
        $(".player-info",this.hostEl).innerHTML = "";
        $(".player-info",this.hostEl)._.contents([
            {
                tag : "p",
                className : "player-state",
                contents : state,
                style : {
                    color : stateColor || trackColor
                }
            },{
                tag : "p",
                className : "player-track",
                contents : !!this.clipUrl ? [{tag:"a",href:this.clipUrl,contents:this.clipTitle}] :this.clipTitle,
                style : {
                    color : stateColor || playheadColor
                }
            }
        ]);
    }

    loadclip({src,title="[untitled]",url=null}) {
        if (!src) return;
        return new Promise((resolve,reject)=>{
            this.src = src;
            this.clipTitle = title;
            this.clipUrl = url;
            this.clipInfo("Loading:");
            Howler.unload();
            Howler.volume(1);
            const onload = ()=>{
                this.clipInfo("Paused:");
                this.trackLength = this.clip.duration();
                this.update();
                resolve();
            };
            this.clip = new Howl({
                html5 : true,
                onload,
                onloaderror : reject,
                src
            });
        });

    }

    pauseclip() {
        if (!this.running) return false;
        this.stopRunning();
        this.clip.pause();
        this.clipInfo("Paused:");
        this.playerLoop = false;
        return this;
    }

    seekClip(amount) {
        if (!this.running) return false;
        this.clip.seek(this.clip.duration()*amount);
        return this;
    }

    playclip() {
        if (this.running) return false;
        this.startRunning();
        this.clip.once('end',()=>{
            this.stopclip();
        });
        this.clip.on('playerror',(err)=>{
            console.error({err});
        })
        this.clip.play();
        this.clipInfo("Now Playing:");
        this.playerLoop = ()=>{
            let playerLoop = this.playerLoop;
            this.playhead = this.clip.seek();
            this.drawPlayhead();
            return !!playerLoop ? requestAnimationFrame(playerLoop) : false;
        }
        this.playerLoop();
        return this;
    }

    stopclip() {
        if (!this.running) return Promise.resolve(false);
        this.stopRunning();
        this.clip.stop();
        this.clipInfo("Paused:");
        let globalAlpha = 25;
        return new Promise((resolve,reject)=>{
            this.playerLoop = ()=>{
                this.playerCtx.globalAlpha = globalAlpha/25;
                this.playerGrainMax = _.floor(interpolate({b:this.playerGrainMax,a:1,time:globalAlpha,duration:25,easing:"easeOutQuad"}));
                globalAlpha--;
                this.drawPlayhead();
                if (globalAlpha < 0) {
                    this.playerCtx.globalAlpha = 1; // reset it
                    this.playerGrainMax = playerGrainDefault;
                    this.playerloop = false;
                    resolve();
                    return false;
                }
                else {
                    return requestAnimationFrame(this.playerLoop);
                }
            };
        });
    }

    handleClick(evt,player){
        evt.stopPropagation();
        evt.preventDefault();
        let clickHit = [evt.offsetX,evt.offsetY];
        let buttonZone = new Path2D();
        buttonZone.arc(...this.center,this.unit(this.buttonRadius),0,2*Math.PI);
        let trackStroke = new Path2D();
        player.trackCtx.lineWidth = 15; // 15 pixel hit zone?
        trackStroke.arc(...this.center,this.unit(this.trackRadius),0,2*Math.PI);
        if (player.trackCtx.isPointInPath(buttonZone,...clickHit)) {
            return player.running ? player.pauseclip() : player.playclip();
        }
        else if (player.trackCtx.isPointInStroke(trackStroke,...clickHit)) {
            let seekHit = angleBetween(...player.center,...clickHit)+90;
            seekHit += seekHit < 0 ? 360 : 0;
            return player.running ? player.seekClip(seekHit/360) : player.playclip().seekClip(seekHit/360);
        }
        else {
            return false;
        }
    }

    unit(v) {
        return v*this.playerWidth;
    }

}

export {
    RotoPlayer
}