import { RotoPlayer } from "./player";
import { SinglePath } from "./drawing";
import 'blissfuljs';

const ratio = devicePixelRatio;

const drawNotedCircle = async function() {
    // draw the background circle
    const canv = $("#backcircle");
    if (canv) {
        canv.width = canv.clientWidth * ratio;
        canv.height = canv.clientHeight * ratio;
        const ctx = canv.getContext("2d");
        const path = new SinglePath().arc({
            center : [(canv.clientWidth) / 2, (canv.clientHeight) / 2],
            radius : canv.clientHeight * .4,
            startAng:30,
            endAng:360+30,
            anticlockwise:false
        });
        ctx.fillStyle = "#AE9985";
        await path.drawVariablePath(ctx, {leaky:true,slowDraw:1,jitter:0.1,grain:220,pen:"dot",thinnest:2,thickest:6});
    };
};

const drawRollingButtons = function(){
    const rollers = $$(".rolling-button");
    rollers.forEach((canv)=>{
        canv.width = canv.clientWidth * ratio;
        canv.height = canv.clientHeight * ratio;
        const ctx = canv.getContext("2d");
        const path = new SinglePath().arc({
            center : [(canv.clientWidth) / 2, (canv.clientHeight) / 2],
            radius : canv.clientHeight * .36,
            startAng:70,
            endAng:360,
            anticlockwise:false
        });
        ctx.fillStyle = "#452517";
        path.drawVariablePath(ctx, {jitter:0.3,grain:50,pen:"dot",thinnest:1,thickest:2});
    });
}

let player;
const initializePlayer = function() {
    if ($(".noted-header")) {  
        $(".noted-header").insertAdjacentHTML("afterend",`<div class="noted-player w-[300px] h-[200px] md:self-start md:justify-self-end mt-12"></div>`);
        $(".noted-header").remove();
    }
    else return;
    // 
    player = new RotoPlayer($(".noted-player"),{
        customPlayMark : ()=>{return;},
        customPauseMark : ()=>{return;},
        noFlex : true,
        noInfo : false,
        buttonRadius : .15,
        fontFamily : 'UrgentTelegramAOE',
        labelSize : .05,
        playhead : 0,
        playheadColor : "rgb(69 26 3)",
        playheadLabelAngle : 30,
        runnerRadius : .255,
        stateColor : "#452517",
        trackRadius : .265,
        trackColor : "#E3DAC8",
        trackLength : 30, // track length in seconds
        trackWidth : .01,
        trackVariation : 1 // factor to bulge thickest part of track        
    }).initialise();

};

$.ready(async function() {

    // format any typewriter elements

    const variedStamps = document.querySelectorAll("[data-varied-stamp]");

    Array.from(variedStamps).forEach((el) => {
        //  not fussing about innerHTML, so only use on elements w/out markup inside
        el.innerHTML = el.innerText.replace(/\s/gm,"§").split("").map(ltr=>`<span class="varied-stamp" style="display:inline-block;opacity:${0.8+(Math.random()*0.2)};translate:${Math.round((Math.random()-1)*0.15)}em ${Math.round((Math.random()-1)*0.15)}em">${ltr == "§" ? "&nbsp;" : ltr}</span>`).join("");
        el.removeAttribute("data-varied-stamp");
        return;
    });

    drawNotedCircle();
    drawRollingButtons();

    // set up play functionalitys
    const playButtons = $$("[data-play]");
    const handleplaychange = (player)=>{
        console.log('play change!',player.src,player);
        playButtons.forEach((btn)=>{
            const src = btn.dataset.play;
            if ((src == player.src) && (player.running)) btn.classList.add("playing");
            else btn.classList.remove("playing");
        });
    };
    playButtons.forEach((btn)=>{
        const src = btn.dataset.play;
        const title = btn.dataset.playTitle;
        btn.addEventListener("click",async(evt)=>{
            if (!player) { 
                initializePlayer();
                player.onplaychange = handleplaychange; 
            }
            if ((player.running === true) && (player.src == src)) player.pauseclip();
            else if (player.running === true) {
                await player.stopclip();
                if (player.title == title) return;
                else {
                    await player.loadclip({src,title});
                    player.playclip();
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    return;
                }
            }
            else if ((player.running !== true) && (player.src == src)) {
                player.playclip();
                return;
            }
            else {
                await player.stopclip()
                await player.loadclip({src,title});
                player.playclip();
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                return;
            }
        });
    });

});
